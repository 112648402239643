import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function About() {
  return  (
      <Layout>
      <SEO 
        pagetitle="会社概要"
        pagedesc="株式会社わくわくスタディワールドの会社概要"
      />
      <div className="keni-main_wrap">
        <div className="keni-main_outer">
          <main id="main" className="keni-main">
            <div className="keni-main_inner">
              <div className="keni-section_wrap">
                <article className="keni-section"><h1 className="section-title">会社概要</h1>
                  <div className="article-body">
                    <p>株式会社わくわくスタディワールドは，IT分野を中心に，楽しく効果的に学んで合格する方法を提案し，そのための教材やセミナーなどを提供する会社です。</p>
                    <p><span className="red b">「勉強は，実は楽しい」</span>をコンセプトに，わくわくと楽しみながら学んでいくための方法を考案しています。</p>
                    <p>学習には順番があり，人それぞれに個性がありますので，既存の教育の枠の中だけではみんなを満足させることはできないことを感じています。そこで，本当に効果的な学習を進めるために，いろんな教材やサービスを作って，新しい勉強法を提案していこうと考え，会社を設立いたしました。</p>
                    <p>当サイトをご覧になった皆様，当社とご縁のある皆様が，楽しく学習し，実際に役立つスキルを身につけるための力になれば幸いです。</p>
                    <p className="al-r">株式会社わくわくスタディワールド　代表取締役　瀬戸美月</p>
                    <p className="al-r"><a href="/profile/">→社員のプロフィールはこちら</a></p>
                  </div>
                  <p>{/*/コンテンツ*/}<br />
                    {/*コンテンツ*/}</p>
                  <h3>株式会社わくわくスタディワールドの経営理念</h3>
                  <h4>ビジョン</h4>
                  <div className="contents">
                    <ul>
                      <li>すべての人がわくわくして，「学ぶことが楽しい！」と，日々喜びを感じられる社会を実現する。</li>
                      <li>すべての人が，自分のやりたいことを実現するために必要な学びを得られる社会を実現する。</li>
                      <li>新しい研究成果などを利用して，学びという概念を再構築し，新しい学びのプロセスを創り出す。</li>
                    </ul>
                  </div>
                  <h4>ミッション</h4>
                  <div className="contents">
                    <ul>
                      <li>「本質的な，わくわくする学び」を，今までの常識にとらわれずに提供する。</li>
                      <li>勉強することを通して，人々がつながり成長していく場を創り上げていく。</li>
                      <li>勉強は実は楽しい，ということが多くの人に実感できる学びの場を提供する。</li>
                      <li>一人一人の能力や状況に合った，最適な学びを提供する仕組みを作る。</li>
                      <li>勉強することを通じて，自分に自信をもつためのお手伝いをする。</li>
                    </ul>
                  </div>
                  <p>{/*/コンテンツ*/}</p>
                  <p>{/*コンテンツ*/}</p>
                  <h3>株式会社わくわくスタディワールドのこれまでの歴史</h3>
                  <div className="contents">
                    <ul className="topics">
                      <li className="noborder">2000年5月 1日・・・個人事業　美月スタジオを千葉県松戸市で設立</li>
                      <li>2007年 4月11日・・・株式会社わくわくスタディワールドとして法人化</li>
                      <li>2010年 7月 1日・・・本社を東京都台東区に移転</li>
                      <li>2011年12月26日・・・徹底攻略応用情報技術者教科書の初版が発売</li>
                      <li>2013年 2月22日・・・徹底攻略ネットワークスペシャリスト教科書の初版が発売</li>
                      <li>2013年 9月24日・・・徹底攻略データベーススペシャリスト教科書の初版が発売</li>
                      <li>2015年 4月22日・・・本社を和歌山県田辺市本宮町に移転</li>
                      <li>2016年 1月 1日・・・徹底攻略情報セキュリティマネジメント教科書の初版が発売</li>
                      <li>2017年 7月21日・・・徹底攻略情報処理安全確保支援士教科書の初版が発売</li>
                    </ul>
                  </div>
                  <p>{/*/コンテンツ*/}</p>
                  <p>{/*コンテンツ*/}</p>
                  <h3>企業概要</h3>
                  <div className="contents">
                    <dl className="dl-style01">
                      <dt>社名</dt>
                      <dd>株式会社わくわくスタディワールド<br />
                        Wakuwaku Study World Co. Ltd.（略称：わく☆すた）</dd>
                      <dt>所在地</dt>
                      <dd>〒647-1705<br />
                        和歌山県田辺市本宮町高山496</dd>
                      <dt>設立</dt>
                      <dd>2007年4月11日</dd>
                      <dt>資本金</dt>
                      <dd>300万円</dd>
                      <dt>役員</dt>
                      <dd>代表取締役：齋藤稔代 Toshiyo Saitoh （ペンネーム：瀬戸美月）<br />
                        取締役：齋藤健一</dd>
                      <dt>事業内容</dt>
                      <dd>情報処理技術者試験に関する教材の作成・販売<br />
                        情報技術やその周辺に関する研修・セミナーの実施<br />
                        新しい勉強法や生き方に関する提案・セミナー実施
                      </dd>
                    </dl>
                  </div>
                </article>
              </div>
            </div>
          </main>
        </div>
      </div>
      </Layout>
  )
}